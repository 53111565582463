import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useGlobalState } from '../hooks/useGlobalState';

const SearchForm = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
`;

const SearchInput = styled.input`
  background: transparent;
  border-bottom: 1px solid ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.primary};
  ${(props) => props.theme.typography.footerBody2}
  margin-left: -1.5em;
  padding-left: 1.5em;
  width: 100%;

  ::placeholder {
    ${(props) => props.theme.typography.footerBody2}
    color: ${(props) => props.theme.color.primary};
  }
`;

const SearchIcon = styled.svg`
  color: ${(props) => props.theme.color.primary};
  margin: 0.3em;
  opacity: 0.8;
  pointer-events: none;
  width: 1em;
`;

interface SearchInsideEdgeProps {
  className?: string;
}

const SearchInsideEdge: React.FC<SearchInsideEdgeProps> = (props) => {
  const { className } = props;
  const { location } = useGlobalState();
  const [currentSearchInput, setCurrentSearchInput] = React.useState('');

  const onSubmit = (event) => {
    event.preventDefault();

    if (currentSearchInput && currentSearchInput.length >= 3) {
      navigate(`${location.pathname}?q=${currentSearchInput}`);
    }
  };

  return (
    <SearchForm className={className} onSubmit={onSubmit}>
      <SearchInput
        type="text"
        placeholder="Search..."
        aria-label="Search"
        value={currentSearchInput}
        onChange={(e) => setCurrentSearchInput(e.target.value)}
      />
      <SearchIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M15.3125 14.0469L10.9503 9.68398C11.6533 8.71794 12.0315 7.55366 12.0303 6.35891C12.0303 3.23176 9.48605 0.6875 6.35891 0.6875C3.23176 0.6875 0.6875 3.23176 0.6875 6.35891C0.6875 9.48605 3.23176 12.0303 6.35891 12.0303C7.55366 12.0315 8.71794 11.6533 9.68398 10.9503L14.0469 15.3125L15.3125 14.0469ZM6.35891 10.2391C5.59138 10.2392 4.84106 10.0116 4.20286 9.58526C3.56465 9.15889 3.06722 8.55283 2.77346 7.84374C2.47971 7.13465 2.40283 6.35437 2.55255 5.60158C2.70227 4.8488 3.07187 4.15732 3.61459 3.61459C4.15732 3.07187 4.8488 2.70227 5.60158 2.55255C6.35437 2.40283 7.13465 2.47971 7.84374 2.77346C8.55283 3.06722 9.15889 3.56465 9.58526 4.20286C10.0116 4.84106 10.2392 5.59138 10.2391 6.35891C10.2379 7.38763 9.8287 8.37387 9.10128 9.10128C8.37387 9.8287 7.38763 10.2379 6.35891 10.2391Z"
          fill="#231F20"
        />
      </SearchIcon>
    </SearchForm>
  );
};

export default SearchInsideEdge;
